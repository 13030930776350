<template>
  <v-timeline
    ref="timeline"
    :direction="xs ? 'vertical' : 'horizontal'"
    :side="singleSide ? 'end' : undefined"
    style="overflow-x: auto"
  >
    <v-timeline-item
      :dot-color="
        project.dates.lastUpdated
          ? 'blue'
          : project.dates.finished
            ? 'green'
            : 'yellow'
      "
      v-for="project in projects"
      :key="project.id"
    >
      <TimeFormatted
        v-if="xs"
        :date="
          project.dates.lastUpdated ??
          project.dates.finished ??
          project.dates.started
        "
      />
      <template v-slot:opposite v-if="!xs">
        <TimeFormatted
          :date="
            project.dates.lastUpdated ??
            project.dates.finished ??
            project.dates.started
          "
        />
      </template>
      <v-responsive>
        <SmallCard :project="project" :forceWrap="!xs" />
      </v-responsive>
    </v-timeline-item>
  </v-timeline>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";
import { TransformedProject } from "@/types";
export default defineComponent({
  setup() {
    // Destructure only the keys we want to use
    const { xs } = useDisplay();

    return { xs };
  },
  props: {
    projects: {
      type: Array as PropType<TransformedProject[]>,
      required: true,
    },
  },
  computed: {
    singleSide() {
      if (this.xs) return true;
      if (this.checkingLarge) return false;
      if (this.largeCompatible) return false;
      return true;
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        if (this.checkingLarge) return;
        this.checkingLarge = true;
        setTimeout(() => {
          this.largeCompatible =
            document.scrollingElement!.clientHeight >=
            document.scrollingElement!.scrollHeight;
          this.checkingLarge = false;
        }, 1);
      }, 100);
    },
  },
  data() {
    return {
      checkingLarge: false,
      largeCompatible: true,
      resizeTimeout: setTimeout(() => {}) as ReturnType<typeof setTimeout>,
    };
  },
});
</script>
