<template>
  <v-row align="end">
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="2"
      xl="2"
      v-for="project in projects"
      :key="project.id"
    >
      <SmallCard :project="project" />
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TransformedProject } from "@/types";
export default defineComponent({
  props: {
    projects: {
      type: Array as PropType<TransformedProject[]>,
      required: true,
    },
  },
});
</script>
